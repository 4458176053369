import React, { useMemo } from "react";
import checkOk from "assets/img/check_ok.png";
import checkNot from "assets/img/check_not.png";
import checkWarning from "assets/img/check-warning.png";
import styles from "./IconeStatus.module.scss"; 
 

export interface IconeStatusProps {
    // Status mostra qual ícone será utilizado
    status: "ok" | "not" | "warning";

    // Mensagem a ser mostrada embaixo do ícone
    mensagem?: string;
}

/**
 * Ícone de status, para mostrar 3 tipos de status em formato de imagem.
 * Opcionalmente, conta com uma mensagem embaixo.
 * 
 * @author Igor Augusto de Faria Costa
 */
const IconeStatus : React.FC<IconeStatusProps> = ({status, mensagem}) => {

    const urlIcone = useMemo(() => {
        switch(status) {
        case "ok":
            return checkOk;
        case "not":
            return checkNot;
        case "warning":
            return checkWarning;
        }
    }, [status]);

    return (
        <div className="text-center">
            <img src={urlIcone} alt={mensagem} />
            {mensagem && <div className={styles.mensagem}>{mensagem}</div>}
        </div>
    );
};

export default IconeStatus;