/* eslint-disable linebreak-style */
import React from "react";
import { Col, Row } from "react-dsgov";
import useCapagState from "../../../state/hooks/useCapagState";
import useMessageState from "../../../state/hooks/useMessageState";
import useErroCapagState from "../../../state/hooks/useErroCapagState";
import useLoadingCapagState from "../../../state/hooks/useLoadingCapagState";
import ReactLoading from "react-loading";
import CapagCard from "./CapagCard";
import CapagTable from "./CapagTable";
import useMostrarCapagState from "../../../state/hooks/useMostrarCapagState";
import classNames from "classnames";
import styles from "./Capag.module.scss";
import useFiltrosState from "../../../state/hooks/useFiltrosState";
import IconeInfo from "../../../components/IconeInfo";
import IconeStatus from "../../../components/IconeStatus";
import IconeMensagem from "../../../components/IconeMensagem";
import FadeInLeftDiv from "../../../components/Animations/FadeInLeftDiv";
import FadeInRightDiv from "../../../components/Animations/FadeInRightDiv";

/**
 * Componente para renderizar as informações da CAPAG.
 * 
 * @author Igor Augusto de Faria Costa
 */
const Capag : React.FC = () => {
    const [capag] = useCapagState();
    const [message] = useMessageState();
    const [erroCapag] = useErroCapagState();
    const [filtros] = useFiltrosState();
    const [loadingCapag] = useLoadingCapagState();
    const [mostrarCapag] = useMostrarCapagState();

    // Cabeçalho da CAPAG, para reutilização
    const header = <h3 className="text-center" data-rh="Os entes federativos que obtiverem nota de Capag “A”, “A+”, “B” ou “B+” são elegíveis a obter garantia da União a operações de crédito. Para maiores informações sobre metodologia de análise de Capag, consultar a Portaria MF nº 1.583, de 13 de dezembro de 2023.">CAPAG - Capacidade de Pagamento <IconeInfo /></h3>;

    return (
        <>
            {/* Se a CAPAG ainda estiver carregando, então mostra um widget de carregamento. */}
            {loadingCapag &&
                <div className="d-flex justify-content-center my-5">
                    <div>
                        <div className="d-flex justify-content-center">
                            <ReactLoading type="spin" color="#000000" height={70} width={70} />
                        </div>
                        <div>
                            Calculando CAPAG para o ente selecionado...
                        </div>
                    </div>
                    
                </div>
            }
            {/* Se a capag tiver terminado de carregar, então mostra os dados da CAPAG. */}
            {mostrarCapag && capag && !erroCapag &&
                <>
                    {header}
                    {capag.listaMensagemInformativa != null && capag.listaMensagemInformativa.length > 0 &&
                        <>
                            <div className="d-flex justify-content-center text-center">
                                <IconeMensagem status="warning" listaMensagemInformativa={capag.listaMensagemInformativa} />
                            </div>
                        </>
                        
                    }
                    <Row>
                        <Col lg={3}>
                            {/* Card mostrado à esquerda. */}
                            <FadeInLeftDiv>
                                <CapagCard 
                                    indicador={capag?.capag} 
                                    titulo={"Nota CAPAG *"} 
                                    tipo="geral"
                                    valHeight={capag?.objIndicadorRanking?.notaIndicador == "" ? "262px" : "373px"}     
                                    sufixoIndicador=""
                                />
                            </FadeInLeftDiv>
                        </Col>
                        <Col>
                            {/* Cards mostrados à direita */}
                            <FadeInRightDiv>
                                <Row>
                                    <CapagCard 
                                        indicador={capag?.capagIndicadorI} 
                                        titulo={capag?.objIndicadorI?.titulo} 
                                        nomeIndicador={capag?.objIndicadorI?.nome} 
                                        valorIndicador={capag.indicadorI}
                                        tipo="detalhe"
                                        sufixoIndicador=""
                                    />
                                    <CapagCard 
                                        indicador={capag?.capagIndicadorII} 
                                        titulo={capag?.objIndicadorII?.titulo} 
                                        nomeIndicador={capag?.objIndicadorII?.nome} 
                                        valorIndicador={capag.indicadorII}
                                        tipo="detalhe"
                                        sufixoIndicador=""
                                    />
                                    <CapagCard 
                                        indicador={capag?.capagIndicadorIII} 
                                        titulo={capag?.objIndicadorIII?.titulo} 
                                        nomeIndicador={capag?.objIndicadorIII?.nome}
                                        valorIndicador={capag.indicadorIII}
                                        tipo="detalhe"
                                        sufixoIndicador=""
                                    />
                                    {capag?.objIndicadorRanking?.notaIndicador != "" &&
                                        <CapagCard 
                                            indicador={capag?.objIndicadorRanking?.notaIndicador} 
                                            titulo={capag?.objIndicadorRanking?.titulo} 
                                            nomeIndicador={capag?.objIndicadorRanking?.nome}
                                            valorIndicador={capag.objIndicadorRanking.indicadorValor}
                                            tipo="detalhe"
                                            sufixoIndicador="icf"
                                        />
                                    }
                                </Row>
                            </FadeInRightDiv>
                        </Col>
                    </Row>

                    {/* Tabela da CAPAG */}
                    <CapagTable memoriaCalculo={capag?.memoriaCalculo} />

                    <div className="my-5 text-center">
                        <i>Fonte: SICONFI</i>
                    </div>

                    <div className={classNames("text-justify", styles["legenda-capag"])}>
                        * O resultado apurado para a CAPAG neste painel não vincula a posição do Tesouro Nacional. O cálculo definitivo da CAPAG será efetuado por ocasião da verificação do cumprimento dos limites e condições para contratação de operações de crédito com garantia da União.
                    </div>

                    {/* Mensagem abaixo exclusiva quando se está filtrando apenas por unidade da federação, e não por município. */}
                    {!filtros.codigoIbgeMunicipio && filtros.idUF && <div className={classNames("text-justify", styles["legenda-estados"])}>
                        * Os dados utilizados para apuração da nota da CAPAG dos Estados foram ajustados de acordo com o MDF e o MCASP.
                    </div>}
                </>
            }

            {/* Caso o objeto da capag seja nulo, mostrar mensagem de erro. */}
            {mostrarCapag && erroCapag &&
                <>
                    {header}
                    <div className="d-flex justify-content-center text-center">
                        <IconeStatus status="warning" mensagem={message} />
                    </div>
                </>
                
            }
            
        </>
    );
};

export default Capag;