import styled, { keyframes } from "styled-components"; 
import { fadeInRight } from "react-animations";

/**
 * Animação para que os elementos dentro deste componente
 * venham da direita para a esquerda.
 * 
 * @author Igor Augusto de Faria Costa
 */
const fadeInRightAnimation = keyframes`${fadeInRight}`;
const FadeInRightDiv = styled.div`
    animation: 1s ${fadeInRightAnimation}
`;

export default FadeInRightDiv;