import ICapag from "../interfaces/ICapag";
import IFiltro from "../interfaces/IFiltro";
import axios from "axios";

/**
 * Service para obter dados da Capag.
 * 
 * @author Igor Augusto de Faria Costa
 */
export default class CapagService {
    private static _instance: CapagService = new CapagService();


    public static getInstance(): CapagService {
        return this._instance;
    }


    /**
     * Obtém os dados da capag.
     * @param filtros Filtros a serem utilizados.
     * @param tokenBusca Token de aplicativo para a requisição.
     * @returns Promise com os dados da capag obtidos do backend.
     */
    public getDadosCapag(filtros: IFiltro, tokenBusca: string): Promise<ICapag | null> {
        return new Promise<ICapag | null>((resolve, reject) => {

            let urlCapag;
            const ano: string = new Date().getUTCFullYear() + "";

            /**
             * Aborta as requisições anteriores. 
             * Como a requisição pode demorar, é necessário abortar
             * as requisições em curso.
             */


            // Se o código do município tiver sido informado, então pega os dados do endpoint do município
            if (filtros.codigoIbgeMunicipio) {
                urlCapag = `/ws-cauc/rest/capag/municipio/${filtros.codigoIbgeMunicipio}EX/${ano}/${tokenBusca}`;
                // No caso da obtenção de dados do Estado, o resultado
                // vem de uma forma diferente. Então, adequo-o para o 
                // mesmo formato do município.
            } else {
                urlCapag = `/ws-cauc/rest/capag/estado/${filtros.idUF}/${tokenBusca}`;
            }

            axios.get<ICapag>(urlCapag)
                .then((respostaCapag) => {
                    resolve({ ...respostaCapag.data });
                })
                .catch((e) => {
                    if (axios.isCancel(e)) {
                        reject(e.message);
                    } else { 
                        reject(e.response.data);
                    }
                });


        });

    }
}