import React, { useMemo } from "react";
import checkOk from "assets/img/check_ok.png";
import checkNot from "assets/img/check_not.png";
import checkWarning from "assets/img/check-warning.png";
import styles from "./IconeMensagem.module.scss"; 
 

export interface IconeMensagemProps {
    // Mensagem mostra qual ícone será utilizado
    status: "ok" | "not" | "warning";

    // Mensagem a ser mostrada embaixo do ícone
    listaMensagemInformativa: string[];
}

/**
 * Ícone de Mensagem, para mostrar 3 tipos de Mensagem em formato de imagem.
 * Opcionalmente, conta com uma mensagem embaixo.
 * 
 * @author Igor Augusto de Faria Costa
 */
const IconeMensagem : React.FC<IconeMensagemProps> = ({status, listaMensagemInformativa}) => {

    const urlIcone = useMemo(() => {
        switch(status) {
        case "ok":
            return checkOk;
        case "not":
            return checkNot;
        case "warning":
            return checkWarning;
        }
    }, [status]);

    return (
        <div className="text-center">
            <img src={urlIcone} />
            
            <ul>
                {listaMensagemInformativa.map((mensagem, index) => (
                    <li key={index}>{mensagem && <div className={styles.mensagem}>{mensagem}</div>}</li>
                ))}
            </ul>
        </div>
        
    );
};

export default IconeMensagem;