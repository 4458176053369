/* eslint-disable linebreak-style */
import React from "react";
import classNames from "classnames";
import { Card } from "react-dsgov";
import styles from "./CapagCard.module.scss";

export interface CapagCardProps {
    /** Indicador da CAPAG */
    indicador: string;
    /** Título do indicador da CAPAG */
    titulo: string;
    /** Nome do indicador da CAPAG */
    nomeIndicador?: string;
    /** Valor do indicador da CAPAG */
    valorIndicador?: number;    
    /** Tipo do card:
     * - geral: card que aparece à esquerda, grande.
     * - detalhe: card que aparece à direita, pequeno.
     */
    tipo: "geral" | "detalhe";
    valHeight?: string;
    sufixoIndicador?: string;
}

/**
 * Componente de card da Capag.
 *
 * @author Igor Augusto de Faria Costa
 */
const CapagCard : React.FC<CapagCardProps> = ({indicador, titulo, nomeIndicador, valorIndicador, tipo, valHeight, sufixoIndicador}) => {
    return (
        <Card 
            className={classNames(
                styles["card-capag"],
                styles[`card-capag__indicador-${indicador?.toLowerCase().replace(/\+/g, "")}`],
                styles[`card-capag__${tipo}`]
            )}

            density="small" 
            style={tipo === "geral" ? { height: valHeight } : {}}
        >
            <Card.Header>
                <div className="d-flex justify-content-between">
                    <div className="m-half">
                        <i className={indicador.includes("A") || indicador.includes("B") ? "fas fa-check" : "fas fa-exclamation"}></i>
                    </div>
                    <div className="m-half">
                        {titulo}
                    </div>
                </div>                                        
            </Card.Header>
            <Card.Content>
                <div className="d-flex justify-content-between">
                    <div className="m-half">
                        {nomeIndicador}
                    </div>
                    <div className={classNames(
                        "m-half",
                        tipo === "geral" && styles["indicador-geral"]
                    )}>                        
                        {indicador+sufixoIndicador} 
                        { 
                            valorIndicador !=0 ?  valorIndicador &&
                                <span> 
                                    &nbsp;({(valorIndicador*100).toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2})}%)
                                </span>     
                                :
                                <span> 
                                &nbsp;(0,00%)
                                </span>     
                        }
                    </div>
                </div>
                
            </Card.Content>
        </Card>
    );
};

export default CapagCard;