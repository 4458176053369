import React from "react";
import { Col, Row } from "react-dsgov";
import FadeInRightDiv from "../../../components/Animations/FadeInRightDiv";
import FadeInUpDiv from "../../../components/Animations/FadeInUpDiv";
import IconeInfo from "../../../components/IconeInfo";
import useMostrarDespesaPessoalState from "../../../state/hooks/useMostrarDespesaPessoalState";
import useMostrarDividaConsolidadaLiquidaState from "../../../state/hooks/useMostrarDividaConsolidadaLiquidaState";
import DespesaPessoal from "./DespesaPessoal";
import DividaConsolidadaLiquida from "./DividaConsolidadaLiquida";

/**
 * Componente para mostrar os dados da LRF, o que inclui
 * a Despesa Pessoal e a Dívida Consolidada Líquida.
 * 
 * @author Igor Augusto de Faria Costa
 * 
 */
const LRF : React.FC = () => {
    const [mostrarDividaConsolidadaLiquida] = useMostrarDividaConsolidadaLiquidaState();
    const [mostrarDespesaPessoal] = useMostrarDespesaPessoalState();

    return (
        <>
            {mostrarDespesaPessoal && mostrarDividaConsolidadaLiquida &&
                <div>
                    <h3 data-rh="A contratação  de  Operações  de  Crédito subordina-se a limites e condições estabelecidos de forma esparsa no ordenamento jurídico nacional - normatizados na Constituição Federal, em leis ordinárias e complementares, como a LRF, em resoluções do Senado Federal, em especial as de número 40/2001, 43/2001 e 48/2001, além de decretos Presidenciais, resoluções do Conselho Monetário Nacional e portarias do Ministério da Fazenda e da STN." className="text-center">
                        Lei de Responsabilidade Fiscal <IconeInfo />
                    </h3>
                    <Row>
                        <Col sm={12} md={6} >
                            <FadeInUpDiv>
                                <DividaConsolidadaLiquida />
                            </FadeInUpDiv>
                        </Col>
                        <Col sm={12} md={6}>
                            <FadeInRightDiv>
                                <DespesaPessoal />
                            </FadeInRightDiv>
                        </Col>
                    </Row>
                    <div className="my-5 text-center">
                        <i>Fonte: SICONFI</i>
                    </div>
                </div>
            }
        </>
        
    );
};

export default LRF;