import styled, { keyframes } from "styled-components"; 
import { fadeInLeft } from "react-animations";


/**
 * Animação para que os elementos dentro deste componente
 * venham da esquerda para a direita.
 * 
 * @author Igor Augusto de Faria Costa
 */
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;
const FadeInLeftDiv = styled.div`
    animation: 1s ${fadeInLeftAnimation}
`;

export default FadeInLeftDiv;