import React, { Suspense } from "react";
import "./App.css";
import { RecoilRoot } from "recoil";
import PreviaFiscal from "./pages/PreviaFiscal/PreviaFiscal";

function App() {
    return (
        <React.StrictMode>
            <RecoilRoot>
                <Suspense fallback={<>Teste</>}>
                    <PreviaFiscal />
                </Suspense>
            </RecoilRoot>
        </React.StrictMode>
    );
}

export default App;
