import http from "../http";
import IDividaConsolidadaLiquida from "../interfaces/IDividaConsolidadaLiquida";
import IFiltro from "../interfaces/IFiltro";

/**
 * Serice para obter os dados da Dívida Consolidada Líquida.
 * 
 * @author Igor Augusto de Faria Costa
 */
export default class DividaConsolidadaLiquidaService {
    private static _instance : DividaConsolidadaLiquidaService = new DividaConsolidadaLiquidaService();
    public static getInstance() : DividaConsolidadaLiquidaService {
        return this._instance;
    }

    /**
     * Obtém os dados da Dívida Consolidada líquida.
     * 
     * @param filtros Filtros utilizados.
     * @param tokenApp Token da aplicação.
     * @returns Os dados da Dívida Consolidada Líquida.
     */
    public getDadosDividaConsolidada(filtros : IFiltro, tokenApp : string) : Promise<IDividaConsolidadaLiquida | null> {
        return new Promise<IDividaConsolidadaLiquida | null>((resolve) => {
            let url;

            // Se tiver sido informado o código do município, então busca no endpoint de município
            if(filtros.codigoIbgeMunicipio) {
                url = `/ws-cauc/rest/siconfi/PDCLRCLATE/municipio/${filtros.codigoIbgeMunicipio}/${tokenApp}`;
            // Do contrário, busca no endpoint de estados
            } else {
                url = `/ws-cauc/rest/siconfi/PDCLRCLATE/${filtros.idUF}/${tokenApp}`;
            }

            http
                .get<any>(url).then((resposta) => {
                    resolve({valor: resposta.data.celulas.celula.valor, isValido: true});
                })
                .catch(() => {
                    resolve(null);
                });

        });
        
    }
}