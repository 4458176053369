import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row, Select } from "react-dsgov";
import useFiltrosState from "../../../state/hooks/useFiltrosState";
import ReCAPTCHA from "react-google-recaptcha";
import useTokenRecaptchaState from "../../../state/hooks/useTokenRecaptchaState";
import useCaucState from "../../../state/hooks/useCaucState";
import useTokenAppState from "../../../state/hooks/useTokenAppState";
import useCapagState from "../../../state/hooks/useCapagState";
import useMessageState from "../../../state/hooks/useMessageState";
import useErroCapagState from "../../../state/hooks/useErroCapagState";
import useLoadingCapagState from "../../../state/hooks/useLoadingCapagState";
import useLoadingCaucState from "../../../state/hooks/useLoadingCaucState";
import CaucService from "../../../services/CaucService";
import CapagService from "../../../services/CapagService";
import TokenService from "../../../services/TokenService";
import useInstituicoesState from "../../../state/hooks/useInstituicoesState";
import DespesaPessoalService from "../../../services/DespesaPessoalService";
import useDespesaPessoalState from "../../../state/hooks/useDespesaPessoalState";
import DividaConsolidadaLiquidaService from "../../../services/DividaConsolidadaLiquidaService";
import useDividaConsolidadaLiquidaState from "../../../state/hooks/useDividaConsolidadaLiquidaState";
import useMostrarCaucState from "../../../state/hooks/useMostrarCaucState";
import useMostrarCapagState from "../../../state/hooks/useMostrarCapagState";
import useMostrarDespesaPessoalState from "../../../state/hooks/useMostrarDespesaPessoalState";
import useMostrarDividaConsolidadaLiquidaState from "../../../state/hooks/useMostrarDividaConsolidadaLiquidaState";
import InstituicoesService from "services/InstituicoesService";
import EntidadesService from "services/EntidadesService";

/**
 * Componente para mostrar a região de filtros.
 * 
 * @author Igor Augusto de Faria Costa
 */
const Filtros: React.FC = () => {
    const caucService = new CaucService();
    const capagService = CapagService.getInstance();
    const despesaPessoalService = DespesaPessoalService.getInstance();
    const dividaCorrenteLiquidaService = DividaConsolidadaLiquidaService.getInstance();
    const entidadesService = EntidadesService.getInstance();
    const instituicoesService = InstituicoesService.getInstance();
    const tokenService = TokenService.getInstance();

    // Estado de filtros, para guardar os dados dos filtros selecionados
    const [filtros, setFiltros] = useFiltrosState();

    // Estado para armazenar o token do recaptcha
    const [tokenRecaptcha, setTokenRecaptcha] = useTokenRecaptchaState();

    /* Estado para guardar o token do aplicativo retornado pelo backend
     * após envio do token do reCaptcha para o backend. */
    const [tokenApp, setTokenApp] = useTokenAppState();

    // Estado para verificar se o botão de pesquisar está habilitado
    const [podePesquisar, setPodePesquisar] = useState<boolean>(true);

    // Estados para definir os dados do Cauc, Capag, etc
    const [, setCauc] = useCaucState();
    const [, setCapag] = useCapagState();
    const [, setMessage] = useMessageState();
    const [, setErroCapag] = useErroCapagState();
    const [, setInstituicoes] = useInstituicoesState();
    const [, setDespesaPessoal] = useDespesaPessoalState();
    const [, setDividaConsolidadaLiquida] = useDividaConsolidadaLiquidaState();

    // Estado para definir se deve mostrar ou não as regiões do prévia
    const [, setMostrarCauc] = useMostrarCaucState();
    const [, setMostrarCapag] = useMostrarCapagState();
    const [, setMostrarDespesaPessoal] = useMostrarDespesaPessoalState();
    const [, setMostrarDividaConsolidadaLiquida] = useMostrarDividaConsolidadaLiquidaState();

    // Estado para definir se a Capag ou a Cauc estão carregando
    const [, setLoadingCapag] = useLoadingCapagState();
    const [, setLoadingCauc] = useLoadingCaucState();

    // Se a combo de municípios está carregando
    const [municipiosCarregando, setMunicipiosCarregando] = useState<boolean>(false);

    // Options do select de Unidade Federativa
    const [optionsUF, setOptionsUF] = useState<any>([]);

    // Options do select de Municípios
    const [optionsMunicipios, setOptionsMunicipios] = useState<any>([]);

    // Estado para definir se já foi realizada uma busca ou não.
    const [buscaRealizada, setBuscaRealizada] = useState<boolean>(false);

    // Ref para manipulação do Google Recaptcha
    const recapchaRef = useRef<any>();

    // Função para gerir o botão de busca
    const handleBusca = () => {
        if (tokenRecaptcha) {
            // Limpa os estados
            setLoadingCauc(true);
            setLoadingCapag(true);
            setBuscaRealizada(true);
            setMostrarCauc(false);
            setMostrarCapag(false);
            setMostrarDividaConsolidadaLiquida(false);
            setMostrarDespesaPessoal(false);
            //setPodePesquisar(false);
            setPodePesquisar(true);

            // Obtém o token de aplicativo com base no token do recaptcha
            tokenService.getToken(filtros, tokenApp, tokenRecaptcha).then((token) => {
                setTokenApp(token);

                // Obtém os dados do Cauc, e guarda no estado
                caucService.getDadosCauc(filtros, token)
                    .then((dadosCauc) => {
                        setCauc(dadosCauc);
                        setLoadingCauc(false);
                        setMostrarCauc(true);
                    })
                    .catch((e) => {
                        // Se tiver sido cancelado, não faz nada.
                        if (e === "CancelledError") {
                            /* */
                        }
                    });

                // Obtém os dados da Capag, e guarda no estado
                capagService.getDadosCapag(filtros, token)
                    .then((dadosCapag) => {
                        if(dadosCapag?.indicadorI == 999){
                            dadosCapag.indicadorI = 0;
                        }
                        if(dadosCapag?.indicadorII == 999){
                            dadosCapag.indicadorII = 0;
                        }
                        if(dadosCapag?.indicadorIII == 999){
                            dadosCapag.indicadorIII = 0;
                        }
                        setCapag(dadosCapag);
                        setLoadingCapag(false);
                        setMostrarCapag(true);
                        setErroCapag(false);
                    })
                    .catch((e) => {
                        setErroCapag(true);
                        // Se tiver sido cancelado, não faz nada.
                        if (e === "CancelledError") {
                            /* */
                        } else {
                            setMessage(e);
                            setLoadingCapag(false);
                            setMostrarCapag(true);
                        }
                    });

                // Obtém os dados das instituições do Estado ou do Município, e guarda no estado
                instituicoesService.getDadosInstituicoes(filtros).then((instituicoes) => {
                    setInstituicoes(instituicoes);
                });

                // Obtém os dados da Despesa Pessoal e guarda no estado
                despesaPessoalService.getDadosDespesaPessoal(filtros, token).then((despesaPessoal) => {
                    setDespesaPessoal(despesaPessoal);
                    setMostrarDespesaPessoal(true);
                });

                // Obtém os dados da Dívida Consolidada e guarda no estado
                dividaCorrenteLiquidaService.getDadosDividaConsolidada(filtros, token).then((dividaConsolidada) => {
                    setDividaConsolidadaLiquida(dividaConsolidada);
                    setMostrarDividaConsolidadaLiquida(true);
                });
            });
        }
    };

    // Gera as options para a combo de estados
    useEffect(() => {
        entidadesService.getEstados()
            .then((ufs) => {
                const options = [];

                for (const uf of ufs) {
                    options.push({ label: uf.NO_UF, value: uf.ID_UF });
                }

                setOptionsUF(options);
            });
    }, []);

    // Gera as options para a combo dos municípios, caso a combo de estados seja alterada
    useEffect(() => {
        if (filtros.idUF) {
            setFiltros((oldFiltros) => ({ ...oldFiltros, codigoIbgeMunicipio: undefined }));
            setOptionsMunicipios([]);
            setMunicipiosCarregando(true);

            // Obtém os dados do município e monta a combo de options
            entidadesService.getMunicipios(filtros.idUF)
                .then((municipios) => {
                    const options = [];

                    for (const municipio of municipios) {
                        options.push({ label: municipio.NO_MUNICIPIO, value: municipio.CO_IBGE });
                    }

                    setOptionsMunicipios(options);
                    setMunicipiosCarregando(false);
                });
        }

    }, [filtros.idUF, setFiltros]);

    // Caso os filtros mudem, reseta o recaptcha
    useEffect(() => {
        // Reseta o token do recaptcha
        setTokenRecaptcha((oldTokenRecaptcha) => {
            if(oldTokenRecaptcha) {
                recapchaRef.current?.reset(); 
            }
            return null;
        });

        //setTokenRecaptcha("sss");

        setTokenApp(null);
        setBuscaRealizada(false);

        setPodePesquisar(true);

    }, [filtros.idUF, filtros.codigoIbgeMunicipio]);


    return (
        <>
            <Row>
                <Col>
                    <Select
                        label="Unidade Federativa"
                        value={filtros.idUF}
                        onChange={(value: string) => setFiltros((oldFiltros) => ({ ...oldFiltros, idUF: value }))}
                        options={optionsUF}                    
                        placeholder="Digite aqui a Unidade Federativa"
                        style={{ maxWidth: "100%" }}
                    />
                </Col>
                <Col>
                    <Select
                        label="Município"
                        value={filtros.codigoIbgeMunicipio}
                        onChange={(value: string) => setFiltros((oldFiltros: any) => ({ ...oldFiltros, codigoIbgeMunicipio: value }))}
                        options={optionsMunicipios}                                               
                        placeholder="Digite aqui o Município"
                        disabled={!filtros.idUF || municipiosCarregando}
                        style={{ maxWidth: "100%" }}
                    />
                </Col>
            </Row>
            <Row
                my={3}
                className="d-flex justify-content-center"

            >
                <ReCAPTCHA
                    style={{ display: filtros.idUF && !buscaRealizada ? "block" : "none" }}
                    ref={recapchaRef}
                    sitekey="6LfvPwkTAAAAAGFiev5M6TgCbZPdKN0mSDrMoGMf"
                    onChange={(valor) => setTokenRecaptcha(valor)}
                />
            </Row>



            {filtros.idUF && <Row my={3} className="d-flex justify-content-center">
                <Button disabled={tokenRecaptcha === null || !podePesquisar} onClick={handleBusca} primary>Pesquisar</Button>
            </Row>}
        </>
    );
};

export default Filtros;
