import React from "react";
import { Table } from "react-dsgov";
import { MemoriaCalculo } from "../../../../interfaces/ICapag";
import styles from "./CapagTable.module.scss";

export interface CapagTableProps {
    /** Memória de cálculo da CAPAG. */
    memoriaCalculo? : MemoriaCalculo[];
}

/**
 *  Componente para mostrar a tabela de memória de cálculo da
 *  Capag.
 * 
 * @author Igor Augusto de Faria Costa
 */
const CapagTable : React.FC<CapagTableProps> = ({memoriaCalculo}) => {
    return (
        <div>
            {memoriaCalculo != null && memoriaCalculo.length > 0 && <Table 
                className={styles.tabelacapag}
                density="small"
                showPageSelector={false} 
                showTopBar={false}
                showPagination={false}
            >
                <thead>
                    <tr>
                        <th>Indicador</th>
                        <th>Rubrica</th>
                        <th>Coluna</th>
                        <th>Anexo</th>
                        <th>Demonstrativo</th>
                        <th>Exercício</th>
                        <th>Valor R$</th>
                    </tr>
                </thead>
                <tbody>
                    {memoriaCalculo?.map((elemento, index) => 
                        <tr key={index}>
                            <td>{elemento.indicador}</td>
                            <td>{elemento.rubrica}</td>
                            <td>{elemento.coluna}</td>
                            <td>{elemento.anexo}</td>
                            <td>{elemento.demonstrativo}</td>
                            <td>{elemento.exercicio}</td>
                            <td>{elemento.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                        </tr>
                    )}
                </tbody>
            </Table>}
        </div>
        
    );
};

export default CapagTable;