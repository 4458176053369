import { Col, Container, Row } from "react-dsgov";
import Capag from "./Capag";

import Cauc from "./Cauc";
import Filtros from "./Filtros";
import LRF from "./LRF";

import ReactHintFactory from "react-hint";
import "react-hint/css/index.css";
import React from "react";

const ReactHint = ReactHintFactory(React);

/**
 * Página principal do Prévia Fiscal.
 * 
 * @author Igor Augusto de Faria Costa
 */
const PreviaFiscal : React.FC = () => {
    return (
        <Container fluid>
            <ReactHint autoPosition events />
            
            <Row>
                <Col>
                    <Filtros />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Capag />
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <Cauc />
                </Col>
                <Col>
                    <LRF />
                </Col>
            </Row>
       
            
        </Container>
    );
};

export default PreviaFiscal;