import http from "../http";
import IDespesaPessoal from "../interfaces/IDespesaPessoal";
import IFiltro from "../interfaces/IFiltro";

/**
 * Service para obter os dados da Despesa Pessoal.
 * 
 * @author Igor Augusto de Faria Costa
 */
export default class DespesaPessoalService {
    private static _instance : DespesaPessoalService = new DespesaPessoalService();
    public static getInstance() : DespesaPessoalService {
        return this._instance;
    }

    /**
     * Obtém dados da despesa pessoal do backend.
     * 
     * @param filtros Filtros a serem utilizados.
     * @param tokenBusca Token de aplicativo para a requisição.
     * @returns Promise com os dados da Despesa Pessoal obtidos do backend.
     */
    public getDadosDespesaPessoal(filtros : IFiltro, tokenApp : string) : Promise<IDespesaPessoal | null> {
        return new Promise<IDespesaPessoal | null>((resolve) => {
            let urlValor;

            // Se o código da instituição tiver sido informado, usa-o. Do contrário, usa município ou estado.
            if(filtros.codigoSiconfiInstituicao) {
                urlValor = `/ws-cauc/rest/siconfi/DTPPRCLAJU/${filtros.codigoSiconfiInstituicao}/${tokenApp}`;
            } else if(filtros.codigoIbgeMunicipio) {
                urlValor = `/ws-cauc/rest/siconfi/DTPPRCLAJU/${filtros.codigoIbgeMunicipio}EX/${tokenApp}`;
            } else {
                urlValor = `/ws-cauc/rest/siconfi/DTPPRCLAJU/estado/${filtros.idUF}/${tokenApp}`;
            }

            http
                .get<any>(urlValor).then((respostaValor) => {
                    const valor = respostaValor.data.celulas.celula.valor;

                    let urlLimite;

                    if(filtros.codigoIbgeMunicipio) {
                        urlLimite = `/ws-cauc/rest/siconfi/LIMAXPRCL/${filtros.codigoIbgeMunicipio}EX/${tokenApp}`;
                    } else {
                        urlLimite = `/ws-cauc/rest/siconfi/LIMAXPRCL/estado/${filtros.idUF}/${tokenApp}`;
                    }

                    http
                        .get<any>(urlLimite).then((respostaLimite) => {
                            const limite = respostaLimite.data.celulas.celula.valor;

                            resolve({limite: limite, valor: valor, isValido: true});
                        })
                        .catch(() => {
                            resolve(null);
                        });

                    
                })
                .catch(() => {
                    resolve(null);
                });

        });
        
    }
}