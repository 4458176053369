import React, { useMemo } from "react";
import IconeInfo from "../../../../components/IconeInfo";
import IconeStatus from "../../../../components/IconeStatus";
import LinearGauge from "../../../../components/LinearGauge";
import useDividaConsolidadaLiquidaState from "../../../../state/hooks/useDividaConsolidadaLiquidaState";
import useFiltrosState from "../../../../state/hooks/useFiltrosState";
import useMostrarDividaConsolidadaLiquidaState from "../../../../state/hooks/useMostrarDividaConsolidadaLiquidaState";
import styles from "./DividaConsolidadaLiquida.module.scss";

const DividaConsolidadaLiquida : React.FC = () => {
    const [dividaConsolidadaLiquida] = useDividaConsolidadaLiquidaState();
    const [mostrarDividaConsolidadaLiquida] = useMostrarDividaConsolidadaLiquidaState();
    const [filtros] = useFiltrosState();

    // Calcula os valores dos limites, que são diferentes para filtro de estado
    // ou de município
    const limite = useMemo(() => filtros.codigoIbgeMunicipio ? 120 : 200, [filtros]);
    const total = useMemo(() => filtros.codigoIbgeMunicipio ? 170 : 250, [filtros]);

    return (
        <>
            {mostrarDividaConsolidadaLiquida && 
                <div className={styles["divida-consolidada-liquida"]}>
                    <h4 data-rh="Dívida Consolidada Líquida / Receita Corrente Líquida. O limite prudencial é de 200%." className="text-center">
                        Dívida Consolidada Líquida <IconeInfo />
                    </h4>
                    {dividaConsolidadaLiquida && dividaConsolidadaLiquida.valor && <LinearGauge data={[{
                        letter: "DCL/RCL",
                        limit: limite,
                        value: dividaConsolidadaLiquida.valor,
                        total: total,
                        totalColor: "#c50200",
                        limitColor: "#c5c5c5",
                        valueColor: "steelblue"
                    }
                    ]} />}
                    {!dividaConsolidadaLiquida &&
                        <div className="d-flex justify-content-center text-center">
                            <IconeStatus status="warning" mensagem="Não foi possível obter a Dívida Consolidada Líquida para o ente selecionado."/>
                        </div>
                    }
                </div>
            }
        </>
        
    );
};

export default DividaConsolidadaLiquida;