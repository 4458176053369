
import React from "react";

/**
 * Ícone do FontAwesome de "informação". Para ser usado
 * ao lado dos headers.
 * 
 * @author Igor Augusto de Faria Costa
 */
const IconeInfo : React.FC = () => {
    return (
        <i className="fas fa-info-circle" style={{color: "#2596be"}}></i>
    );
};

export default IconeInfo;