
import React, { useEffect, useMemo } from "react";
import styles from "./DespesaPessoal.module.scss";
import ReactSpeedometer from "react-d3-speedometer";
import { Select } from "react-dsgov";
import DespesaPessoalService from "../../../../services/DespesaPessoalService";
import useDespesaPessoalState from "../../../../state/hooks/useDespesaPessoalState";
import useFiltrosState from "../../../../state/hooks/useFiltrosState";
import useInstituicoesState from "../../../../state/hooks/useInstituicoesState";
import useTokenAppState from "../../../../state/hooks/useTokenAppState";
import useMostrarDespesaPessoalState from "../../../../state/hooks/useMostrarDespesaPessoalState";
import IconeInfo from "../../../../components/IconeInfo";
import IconeStatus from "../../../../components/IconeStatus";

/**
 * Componente para mostrar os dados da Despesa Pessoal.
 * 
 * @author Igor Augusto de Faria Costa
 */
const DespesaPessoal: React.FC = () => {
    const despesaPessoalService = DespesaPessoalService.getInstance();

    // Estado para armazenar os filtros
    const [filtros, setFiltros] = useFiltrosState();

    // Estado com o token da aplicação, para usar em todas requisições
    const [tokenApp] = useTokenAppState();

    // Estados diversos
    const [instituicoes] = useInstituicoesState();
    const [despesaPessoal] = useDespesaPessoalState();
    const [, setDespesaPessoal] = useDespesaPessoalState();
    const [mostrarDespesaPessoal] = useMostrarDespesaPessoalState();

    // Monta as options das instituições
    const optionsInstituicoes: any = useMemo(() => {
        const options = [];
        for (const instituicao of instituicoes) {
            options.push({ label: instituicao.nome, value: instituicao.codigoSiconfi });
        }

        return options;
    }, [instituicoes]);

    // Ao trocar o código IBGE da Instituição
    const handleChangeInstituicao = (codigoSiconfiInstituicao: string) => {
        setFiltros((oldFiltros) => ({ ...oldFiltros, codigoSiconfiInstituicao: codigoSiconfiInstituicao }));
    };

    // Ao trocar o filtro da instituição, recarrega os dados da despesa pessoal
    useEffect(() => {
        if (tokenApp) {
            despesaPessoalService.getDadosDespesaPessoal(filtros, tokenApp).then((despesaPessoal) => {
                console.log("acionando setDespesaPessoal "+filtros.codigoSiconfiInstituicao);
                setDespesaPessoal(despesaPessoal);
            });
        }
    }, [filtros, setDespesaPessoal, tokenApp]);

    return (
        <>
            {mostrarDespesaPessoal &&
                <div>
                    <h4 data-rh="Despesa Total com Pessoal / Receita Corrente Líquida. Para verificar os percentuais dos limites da despesa com pessoal de cada esfera e poder, consultar a Seção II da Lei Complementar nº 101/2000." className="text-center">Despesa com pessoal <IconeInfo /></h4>
                    {despesaPessoal && despesaPessoal.valor !== undefined &&
                        <>
                            <Select
                                className={styles["seletor-instituicao"]}
                                options={optionsInstituicoes}
                                value={filtros.codigoSiconfiInstituicao}
                                onChange={(value: string) => handleChangeInstituicao(value)}
                            />
                            <div>
                                <div className={styles["gauge"]}>
                                    {/* Se o valor da despesa pessoal for positivo, mostrar normalmente */}
                                    {despesaPessoal.valor > 0 && despesaPessoal.limite &&
                                        <ReactSpeedometer
                                            minValue={30 / 100}
                                            maxValue={despesaPessoal.limite * 1.5 / 100}
                                            value={despesaPessoal.valor / 100}
                                            textColor="#000000"
                                            customSegmentStops={[30 / 100, 0.9 * despesaPessoal.limite / 100, 0.95 * despesaPessoal.limite / 100, despesaPessoal.limite / 100, 1.5 * despesaPessoal.limite / 100]}
                                            segmentColors={["#8cca2f", "#fdc702", "#f48024", "#c50300"]}
                                            labelFontSize="9px"
                                            currentValueText={despesaPessoal.valor.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%"}
                                            valueFormat=".0%"
                                            fluidWidth
                                            forceRender
                                        />
                                    }
                                    {/* Do contrário, se o valor for zero, mostra um gráfico todo cinza */}
                                    {despesaPessoal.valor === 0 &&
                                        <ReactSpeedometer
                                            minValue={0}
                                            maxValue={1}
                                            value={0}
                                            textColor="#000000"
                                            segmentColors={["#cecece"]}
                                            labelFontSize="9px"
                                            currentValueText={despesaPessoal.valor.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%"}
                                            valueFormat=".0%"
                                            fluidWidth
                                            forceRender
                                        />
                                    }
                                </div>
                                {/* Legendas do gráfico. */}
                                <div className="d-flex justify-content-center text-down-01">
                                    <div className="m-half">
                                        <span className={styles.rectangle} style={{ background: "#8cca2f" }} />
                                        Abaixo do limite
                                    </div>
                                    <div className="m-half">
                                        <span className={styles.rectangle} style={{ background: "#fdc702" }} />
                                        Limite do alerta
                                    </div>
                                    <div className="m-half">
                                        <span className={styles.rectangle} style={{ background: "#f48024" }} />
                                        Limite prudencial
                                    </div>
                                    <div className="m-half">
                                        <span className={styles.rectangle} style={{ background: "#c50300" }} />
                                        Acima do limite
                                    </div>
                                </div>
                            </div>
                        </>

                    }

                    {/* Caso os dados da despesa pessoal não tenham sido carregados, mostrar um erro */}
                    {!despesaPessoal &&
                        <div className="d-flex justify-content-center text-center">
                            <IconeStatus status="warning" mensagem="Não foi possível obter os dados de Despesa Pessoal para o ente selecionado." />
                        </div>
                    }

                </div>
            }
        </>

    );
};

export default DespesaPessoal;