
import IMunicipio from "interfaces/IMunicipio";
import IUnidadeFederativa from "interfaces/IUnidadeFederativa";
import http from "../http";

/**
 * Service para obter dados das entidades.
 * 
 * @author Igor Augusto de Faria Costa
 */
export default class EntidadesService {
    private static _instance : EntidadesService = new EntidadesService();
    public static getInstance() : EntidadesService {
        return this._instance;
    }

    /**
     * Obtém a lista de estados.
     * @returns Lista de estados.
     */
    public getEstados() : Promise<IUnidadeFederativa[]> {
        return new Promise<IUnidadeFederativa[]>((resolve) => {
            http.get<IUnidadeFederativa[]>("/ws-cauc/rest/entes/estados")
                .then((resposta) => {
                    resolve(resposta.data);
                });
        });
    }

    /**
     * Obtém a lista de municípios de uma determinada UF.
     * 
     * @param idUf ID da UF.
     * @returns Lista de municípios.
     */
    public getMunicipios(idUf : string) : Promise<IMunicipio[]> {
        return new Promise<IMunicipio[]>((resolve) => {
            http.get<IMunicipio[]>(`/ws-cauc/rest/entes/municipios/${idUf}`)
                .then((resposta) => {
                    resolve(resposta.data);
                });
        });
    }
}