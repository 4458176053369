import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

export interface DataProps {
    letter: string;
    limit: number;
    value: number;
    total: number;
    limitColor: string;
    valueColor: string;
    totalColor: string;
}

export interface LinearGaugeProps {
    data: DataProps[]
}

/**
 * Componente de LinearGauge. 
 * 
 * Feito baseado no ng-DCL-RCL.js do prévia fiscal antigo.
 * https://gitlab.tesouro.gov.br/cosis/frontend/previa-fiscal-frontend/-/blob/4bbb5d611c8c39c13a9d84ea8b38312e85a56ff2/app/js/ng-DCL-RCL.js
 * 
 * Adaptei para componente React, e para a nova versão do d3.
 */
const LinearGauge: React.FC<LinearGaugeProps> = ({ data }) => {
    const refSvg = useRef<SVGSVGElement>(null);

    useEffect(() => {
        const svg = refSvg.current;
        const maxData = d3.max(data, (d) => d.total);

        svg?.querySelectorAll("*").forEach((element) => element.remove());

        if (svg && maxData) {
            const margin = {
                    top: 20,
                    right: 20,
                    bottom: 30,
                    left: 40,
                    label: 40
                },
                width = 140 - margin.left - margin.right,
                height = 600 - margin.top - margin.bottom;

            const x = d3.scaleBand()
                .domain(data.map((d) => d.letter))
                .rangeRound([20, width])
                .padding(.1);

            const y = d3.scaleLinear()
                .domain([0, maxData])
                .range([height, 0]);

            const xAxis = d3.axisBottom(x);

            const yAxis = d3.axisLeft(y).ticks(15);

            const svgWrapper = d3.select(svg).attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom + margin.label)
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            svgWrapper.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + height + ")")
                .call(xAxis);


            svgWrapper.append("g")
                .attr("class", "y axis")
                .call(yAxis)
                .append("text")
                .attr("fill", "#000000")
                .attr("transform", "rotate(0)")
                .attr("y", 6)
                .attr("x", 16)
                .attr("dy", ".71em")
                .style("text-anchor", "end")
                .text("%")
                .attr("fill", "#000000");

            const g = svgWrapper.selectAll(".bars")
                .data(data)
                .enter().append("g");


            g.append("rect")
                .attr("class", "bar3")
                .attr("x", (d) => {
                    const valor = x(d.letter);
                    return valor || 0;
                })
                .attr("width", x.bandwidth())
                .attr("y",  (d) => y(d.total))
                .attr("height", (d) => height - y(d.total))
                .attr("fill", (d) => d.totalColor);

            g.append("rect")
                .attr("class", "bar2")
                .attr("x", (d) => {
                    const valor = x(d.letter);
                    return valor || 0;
                })
                .attr("width", x.bandwidth())
                .attr("y", (d) => y(d.limit))
                .attr("height", (d) => height - y(d.limit))
                .attr("fill", (d) => d.limitColor);

            
            g.append("rect")
                .attr("class", "bar1")
                .attr("x", (d) => {
                    const valor = x(d.letter);
                    return valor ? valor + 10 : 10; 
                })
                .attr("width", x.bandwidth() - 20)
                .attr("y", ()  => height)
                .attr("height", 0)
                .transition()
                .duration(1000)
                .delay((d, i) => i * 50)
                .attr("height", (d) => height - y(d.value) > 0 ? height - y(d.value) : 0)
                .attr("y", (d) => y(d.value))
                .attr("fill", (d) => d.valueColor);

            g.append("text")
                .attr("x", 10)
                .attr("y", height + 30)
                .attr("dy", "0.75em")
                .text((d) => (d.value.toFixed(2)) + "%")
                .style("fill", "#416094")
                .style("font-size", "20px")
                .style("font-family", "Arial");


        }
    }, [data]);

    return <div className="svg">
        <svg ref={refSvg}>

        </svg>
    </div>;
};

export default LinearGauge;