import React, { useMemo } from "react";
import FadeInLeftDiv from "../../../components/Animations/FadeInLeftDiv";
import IconeInfo from "../../../components/IconeInfo";
import IconeStatus from "../../../components/IconeStatus";
import useCaucState from "../../../state/hooks/useCaucState";
import useMostrarCaucState from "../../../state/hooks/useMostrarCaucState";

/**
 * Componente para mostrar os dados do Cauc.
 * 
 * @author Igor Augusto de Faria Costa
 */
const Cauc: React.FC = () => {
    const [cauc] = useCaucState();
    const [mostrarCauc] = useMostrarCaucState();

    // Cards a serem mostrados referentes ao Cauc.
    const cards = useMemo(() => ([
        { 
            id: 1, 
            title: "Adimplência Financeira", 
            status: cauc?.checkAdimplencia,
            help: "Regularidade em relação à adimplência financeira em empréstimos e financiamentos concedidos pela União" 
        },
        { 
            id: 2, 
            title: "Encaminhamento das contas anuais", 
            status: cauc?.checkContasAnuais, 
            help: "Encaminhamento das Contas Anuais" 
        },
        { 
            id: 3, 
            title: "Aplicação mínima de recursos em saúde", 
            status: cauc?.checkSaude, 
            help: "Aplicação mínima de recursos em saúde" 
        },
        { 
            id: 4, 
            title: "Aplicação mínima de recursos em educação", 
            status: cauc?.checkEducacao, 
            help: "Aplicação mínima de recursos em educação" 
        },
        { 
            id: 5,
            title: "Situação dos demais itens no CAUC", 
            status: cauc?.qtdPendencias === 0, 
            help: "Consultar demais pendências no CAUC" 
        },
    ]), [cauc]);


    return (
        <div className="text-center">
            {mostrarCauc &&
                <>
                    <h3 data-rh-at="right" data-rh="O CAUC é um serviço que disponibiliza informações de requisitos fiscais dos entes subnacionais necessários à transferência de recursos do Governo Federal. Para obtenção de operação de crédito, apenas um item do CAUC é verificado pelo Tesouro: o encaminhamento das contas anuais. Porém, os itens ‘Aplicação mínima de recursos em saúde’ e ‘em educação’, serão apresentados como aproximação do atendimento do piso constitucional de dispêndio com essas despesas. Ademais, para operações de crédito cm garantias, a PGFN avaliará a totalidade do CAUC e, portanto, o Painel apresenta também a situação do ente nos demais itens.">CAUC <IconeInfo /></h3>
                    <FadeInLeftDiv>
                        {cards.map((card) =>
                            <div key={card.id}>
                                <div className="d-flex justify-content-center">
                                    <h4>{card.title}</h4>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div data-rh={card.help} data-rh-at="right">
                                        {/* Caso o cauc tenha sido carregado, mostra o card com o status correspondente. */}
                                        {cauc && <IconeStatus status={card.status ? "ok" : "not"} />}

                                        {/* Do contrário, mostra um ícone de erro */}
                                        {!cauc && <IconeStatus status="warning" mensagem="Falha ao obter dados do CAUC" />}
                                    </div>
                                </div>
                            </div>
                        )}
                        {cauc && <div className="my-2">
                            Outras {cauc.qtdPendencias} pendências.
                        </div>}
                        <div className="my-5">
                            <i>Fonte: CAUC</i>
                        </div>
                    </FadeInLeftDiv>
                </>
            }
        </div>
    );
};

export default Cauc;