import http from "../http";
import ICauc from "../interfaces/ICauc";
import IFiltro from "../interfaces/IFiltro";

/**
 * Service para obter os dados do Cauc.
 * 
 * @author Igor Augusto de Faria Costa
 */
export default class CaucService {
    private static _instance : CaucService = new CaucService();

    constructor() {
        this.abortControllers = []; 
    }

    public static getInstance() : CaucService {
        return this._instance;
    }

    abortControllers : AbortController[];

    /**
     * Obtém os dados do cauc.
     * @param filtros Filtros a serem utilizados.
     * @param tokenBusca Token de aplicativo para a requisição.
     * @returns Promise com os dados do cauc obtidos do backend.
     */
    public getDadosCauc(filtros : IFiltro, tokenBusca : string) : Promise<ICauc | null> {
        return new Promise<ICauc | null>((resolve, reject) => {
            // Busca os dados da Cauc
            let urlCauc;

            // Se tiver sido informado o código do município, então busca no endpoint de município
            if(filtros.codigoIbgeMunicipio) {
                urlCauc = `/ws-cauc/rest/caucMunicipio?coIBGE=${filtros.codigoIbgeMunicipio}&itens=1.4,3.3,5.1,5.2&token=${tokenBusca}`;
            // Do contrário, busca no endpoint de estados
            } else {
                urlCauc = `/ws-cauc/rest/cauc?idUF=${filtros.idUF}&itens=1.4,3.3,5.1,5.2&token=${tokenBusca}`;
            }

            // Aborta as requisições anteriores
            for (const prevAbortController of this.abortControllers) {
                if(!prevAbortController.signal.aborted) {
                    prevAbortController.abort();
                }             
            }

            const abortController = new AbortController();
            this.abortControllers.push(abortController);

            http.get<any>(urlCauc, {signal: abortController.signal})
                .then((respostaCauc) => {
                    // Define os valores iniciais como true
                    const dadosCauc : ICauc = {
                        checkAdimplencia: true,
                        checkContasAnuais: true,
                        checkEducacao: true,
                        checkPendencias: true,
                        checkSaude: true
                    };
            
                    // Procura na resposta cada uma das situações e define os valores corretos
                    for(const itemLegal of respostaCauc.data.regularidade.itensLegais.itemLegal) {
                        if(itemLegal.codigo === 1.4) {
                            dadosCauc.checkAdimplencia = itemLegal.situacao !== 0;
                        }
            
                        if(itemLegal.codigo === 3.3) {
                            dadosCauc.checkContasAnuais = itemLegal.situacao !== 0;
                        }
            
                        if(itemLegal.codigo === 4.3) {
                            dadosCauc.checkSaude = itemLegal.situacao !== 0;
                        }
            
                        if(itemLegal.codigo === 4.2) {
                            dadosCauc.checkEducacao = itemLegal.situacao !== 0;
                        }
                        
                    }
            
                    dadosCauc.qtdPendencias = respostaCauc.data.regularidade.quantidadePendencias;
                    
                    resolve(dadosCauc);
                })
                .catch((e) => {
                    // Se houver um erro qualquer na obtenção dos dados, retorna null
                    if(e.name === "CanceledError") {
                        reject(e.name);
                    } else {
                        resolve(null);
                    }  
                }); 
        });
        
    }
}