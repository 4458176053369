import { atom } from "recoil";
import ICapag from "../interfaces/ICapag";
import ICauc from "../interfaces/ICauc";
import IDespesaPessoal from "../interfaces/IDespesaPessoal";
import IDividaConsolidadaLiquida from "../interfaces/IDividaConsolidadaLiquida";
import IFiltro from "../interfaces/IFiltro";
import IIinstituicao from "../interfaces/IInstituicao";

/**
 * Gerenciamento de estados global
 * utilizando Recoil.js.
 */

/**
 * Armazena os filtros do relatório.
 */
export const filtros = atom<IFiltro>({
    key: "filtros",
    default: {}
});

/**
 * Armazena o token recebido pelo Recaptcha.
 */
export const tokenRecaptcha = atom<string | null>({
    key: "tokenRecaptcha",
    default: null
});

/**
 * Armazena o token da aplicação, obtido do back-end, e utilizado
 * para todas as futuras requisições.
 */
export const tokenApp = atom<string | null>({
    key: "tokenApp",
    default: null
});

/**
 * Armazena os dados do cauc.
 */
export const cauc = atom<ICauc | null>({
    key: "cauc",
    default: null
});

/**
 * Armazena os dados da capag.
 */
export const capag = atom<ICapag | null>({
    key: "capag",
    default: null
});

/**
 * Armazena a mensagem do backend.
 */
export const message = atom<string>({
    key: "message",
    default: "Não foi possível obter a CAPAG."
});

/**
 * Armazena a mensagem do backend.
 */
export const erroCapag = atom<boolean>({
    key: "erroCapag",
    default: false
});

/**
 * Dados da Despesa Pessoal.
 */
export const despesaPessoal = atom<IDespesaPessoal | null>({
    key: "despesaPessoal",
    default: {}
}); 

/**
 * Dados da Dívida Consolidada Líquida.
 */
export const dividaConsolidadaLiquida = atom<IDividaConsolidadaLiquida | null>({
    key: "dividaConsolidadaLiquida",
    default: {}
}); 

/**
 * Dados das instituições de um estado ou município.
 */
export const instituicoes = atom<IIinstituicao[]>({
    key: "instituicoes",
    default: []
});

/**
 * Armazena a informação se está carregando os dados da Cauc.
 */
export const loadingCauc = atom<boolean>({
    key: "loadingCauc",
    default: false
});

/**
 * Armazena a informação se está carregnado os dados da Capag.
 */
export const loadingCapag = atom<boolean>({
    key: "loadingCapag",
    default: false
});

/**
 * Armazena a informação se está carregando os dados
 * da Despesa Pessoal.
 */
export const loadingDespesaPessoal = atom<boolean>({
    key: "loadingDespesaPessoal",
    default: false
});

/**
 * Armazena a informação se está carregando os dados
 * da Dívida Líquida Consolidada.
 */
export const loadingDividaConsolidadaLiquida = atom<boolean>({
    key: "loadingDividaConsolidadaLiquida",
    default: false
});

/**
 * Se deve ou não mostrar os dados do Cauc em tela.
 */
export const mostrarCauc = atom<boolean>({
    key: "mostrarCauc",
    default: false
});

/**
 * Se deve ou não mostrar os dados da Capag em tela.
 */
export const mostrarCapag = atom<boolean>({
    key: "mostrarCapag",
    default: false
});

/**
 * Se deve ou não mostrar os dados da Despesa Pessoal
 * em tela.
 */
export const mostrarDespesaPessoal = atom<boolean>({
    key: "mostrarDespesaPessoal",
    default: false
});

/**
 * Se deve ou não mostrar os dados da Dívida Líquida
 * Consolidada em tela.
 */
export const mostrarDividaConsolidadaLiquida = atom<boolean>({
    key: "mostrarDividaConsolidadaLiquida",
    default: false
});
