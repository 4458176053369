import styled, { keyframes } from "styled-components"; 
import { fadeInUp } from "react-animations";

/**
 * Animação para que os elementos dentro deste componente
 * venham de baixo para cima.
 * 
 * @author Igor Augusto de Faria Costa
 */
const fadeInUpAnimation = keyframes`${fadeInUp}`;
const FadeInUpDiv = styled.div`
    animation: 1s ${fadeInUpAnimation}
`;

export default FadeInUpDiv;