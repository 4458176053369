import http from "../http";
import IFiltro from "../interfaces/IFiltro";
import IIinstituicao from "../interfaces/IInstituicao";

/**
 * Service para obter dados das instituições.
 * 
 * @author Igor Augusto de Faria Costa
 */
export default class InstituicoesService {
    private static _instance : InstituicoesService = new InstituicoesService();
    public static getInstance() : InstituicoesService {
        return this._instance;
    }

    /**
     * Obtém os dados das instituições de acordo com os filtros informados.
     * 
     * @param filtros Filtros a serem utiliados.
     * @returns Dados das instituições.
     */
    public getDadosInstituicoes(filtros : IFiltro) : Promise<IIinstituicao[]> {
        return new Promise<IIinstituicao[]>((resolve) => {
            let urlInstituicoes;

            // Se tiver sido informado o código do município, então busca no endpoint de município
            if(filtros.codigoIbgeMunicipio) {
                urlInstituicoes = `/ws-cauc/rest/entes/municipios/instituicoes/${filtros.codigoIbgeMunicipio}`;
            // Do contrário, busca no endpoint de estados
            } else {
                urlInstituicoes = `/ws-cauc/rest/entes/estados/instituicoes/${filtros.idUF}`;
            }

            http.get<any>(urlInstituicoes).then((respostaInstituicoes) => {
                const instituicoes : IIinstituicao[] = [];

                for (const instituicao of respostaInstituicoes.data) {
                    instituicoes.push({nome: instituicao.NO_INSTITUICAO, codigoSiconfi: instituicao.CO_SICONFI});
                }

                resolve(instituicoes);
            });

        });
        
    }
}
