import http from "../http";
import IFiltro from "../interfaces/IFiltro";

/**
 * Service para obter o token de aplicação do back-end, com base
 * no token do Recaptcha.
 * 
 * O token do recaptcha serve para obter o token da aplicação.
 * É o token da aplicação que é utilizado nas próximas requisições,
 * e não o token do recaptcha.
 * 
 * Este token da aplicação é vinculado ao município ou ao estado
 * em questão, de modo que, ao serem trocados os filtros, é necessário
 * refazer o recaptcha e recriar um novo token de aplicação.
 * 
 * @author Igor Augusto de Faria Costa
 */
export default class TokenService {
    private static _instance : TokenService = new TokenService();
    public static getInstance() : TokenService {
        return this._instance;
    }

    /**
     * Obtém o token de aplciação com base no token do reCaptcha.
     * 
     * @param filtros Filtros da aplicação.
     * @param tokenApp Token da aplicação previamente obtido, caso tenha sido.
     * @param tokenRecaptcha Token obtido por meio do recaptcha.
     * @returns O token de aplicação para ser usado nas próximas requisições.
     */
    public getToken(filtros : IFiltro, tokenApp : string | null, tokenRecaptcha : string) : Promise<string> {
        return new Promise<string>((resolve) => {
            let urlToken;
            
            // Se tiver sido informado o código do município, então busca no endpoint de município
            if(filtros.codigoIbgeMunicipio) {
                urlToken = `/ws-cauc/rest/validaRecaptcha/municipio/${filtros.codigoIbgeMunicipio}/${tokenRecaptcha}`;
            // Do contrário, busca no endpoint de estados
            } else {
                urlToken = `/ws-cauc/rest/validaRecaptcha/estado/${filtros.idUF}/${tokenRecaptcha}`;
            }

            if(tokenApp) {
                resolve(tokenApp);
            } else {
                http.get<string>(urlToken).then((resposta) => {
                    resolve(resposta.data);
                });
            }
        });
    }
}